import { combineReducers } from "redux";
import todo from "./todo";
import songs from "./songs";
import balance from "./balance";
import chat from "./chat";
import stream from "./stream";
import language from "./language";
import streamSong from "./streamSong";
import adv from "./adv";
export default combineReducers({
  todo,
  songs,
  balance,
  chat,
  stream,
  language,
  streamSong,
  adv,
});
