import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter } from "react-router-dom";
import { UserContext } from "./context/userContext";
import RouterOutlet from "./router-outlet";
import { SocketContext, socket } from "./context/socket";
import "./App.scss";
import * as io from "socket.io-client";
import cookieStorage from "./services/cookie-storage";
import { SERVER_URL } from "./constants/api";
import common_en from "./translations/en/common.json";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import ApiService from "./services/api-service";
import { setLanguage } from "./action/language";
import { useDispatch, useSelector } from "react-redux";
import { logger } from "./constants/function";

function App() {
  const { languageObject, language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const url = window.location.href;
  const token = cookieStorage.getCookie("Authorization");

  const [userData, setUser] = useState([]);
  const [Socket, setSocket] = useState();
  const value = useMemo(() => ({ userData, setUser }), [userData, setUser]);
  const socket = useMemo(() => ({ Socket, setSocket }), [Socket, setSocket]);

  useEffect(() => {
    checkUserData();
    var data = localStorage.getItem("userData");
    if (data) getI18nData();
    // logger.disableLogger();
  }, []);

  useEffect(() => {
    if (
      userData &&
      !window.location.href.includes("/app/LiveVideoStreaming/")
    ) {
      setSocket(
        io.connect(SERVER_URL, {
          auth: { JWT: token },
          transports: ["websocket"],
          reconnection: true,
        })
      );
    }
  }, [userData, token]);

  const checkUserData = () => {
    var data = localStorage.getItem("userData");
    if (data) {
      setUser(JSON.parse(data).userData);
    }

    //  console.info(JSON.parse(data));
  };
  const getI18nData = async () => {
    try {
      const lng = cookieStorage.getCookie("language");
      const response = await ApiService.LANGUAGE(
        `language=${lng ? lng : "en"}&class=web`
      );
      const { availableLanguages, languageObject, selectedLanguage, token } =
        response.result;
      if (token) cookieStorage.createCookie("Authorization", token, 1);
      dispatch(
        setLanguage({
          language: selectedLanguage,
          languageObject: languageObject,
          availableLanguages: availableLanguages,
        })
      );
      cookieStorage.createCookie("language", selectedLanguage);
    } catch (error) {
      i18next.init({
        interpolation: { escapeValue: false }, // React already does escaping
        lng: "en", // language to use
        resources: {
          en: {
            common: common_en, // 'common' is our custom namespace
          },
        },
      });
    }
  };
  useEffect(() => {
    //setting selected language to cookies for reload purpose
    cookieStorage.createCookie("language", language ? language : "en");

    const common =
      Object.keys(languageObject).length === 0 ? common_en : languageObject;
    i18next.init({
      interpolation: { escapeValue: false }, // React already does escaping
      lng: language ? language : "en", // language to use
      resources: {
        [language]: {
          common: common, // 'common' is our custom namespace
        },
      },
    });
  }, [languageObject, language]);
  return (
    <I18nextProvider i18n={i18next}>
      <SocketContext.Provider value={socket}>
        <UserContext.Provider value={value}>
          <BrowserRouter>
            <RouterOutlet />
          </BrowserRouter>
        </UserContext.Provider>
      </SocketContext.Provider>
    </I18nextProvider>
  );
}

export default App;
