export const Images={
    gender:require('./resources/Group 10848.png'),
    male:require('./resources/Icon- male.png'),
    female:require('./resources/Icon- male1.png'),
    other:require('./resources/Component 132.png'),
    male_toggle:require('./resources/Icon- male.svg'),
    female_toggle:require('./resources/Icon- female.svg'),
    other_toggle:require('./resources/Other.svg'),
    coin:require('./resources/Coin icon.png'),
    headPhone:require('./resources/Mask Group 13.svg'),
    siki:require('./resources/SIKI.svg'),
    google:require('./resources/google-symbol.svg'),
    telephone:require('./resources/telephone.svg'),
    ticketStar:require('./resources/Iconly-Light-Ticket Star.svg'),
    wallet:require('./resources/Iconly-Light-Wallet.svg'),
    logout:require('./resources/Iconly-Light-Logout.svg'),
    rectangle:require('./resources/Rectangle 21.png'),
    rectangle1:require('./resources/Rectangle 23.png'),
    top10:require('./resources/image (1).png'),
    splash:require('./resources/splash.svg'),
    badge:require('./resources/badge.png'),
    dummy:require('./resources/Ellipse252.png'),
    handshake:require('./resources/handshake.png'),
    tiktok:require('./resources/Tiktokblack.svg'),
    doller:require('./resources/doller.png'),
    handHolding:require('./resources/hand-holding-heart-solid.svg'),
    dummy1:require('./resources/Ellipse.png'),
    dummy2:require('./resources/Ellipse 1.png'),
    dummy3:require('./resources/Ellipse 5.png'),
    tip:require('./resources/Group 10446.svg'),
    upgrade:require('./resources/upgrade_premium_bg.jpg'),
    upgrade1:require('./resources/Premium Banner.png'),
    bid:require('./resources/Mask Group 9.svg'),
    profile:require('./resources/Group 2137.png'),
    profileCover:require('./resources/Rectangle 4664.png'),
    tipOwner:require('./resources/Mask Group 160.svg'),
    back:require('./resources/fi-rr-angle-left.svg'),
    subscription:require('./resources/subscription.png'),
    royalty:require('./resources/royalty.png'),
    send_tip:require('./resources/send_tip.png'),
    instagram:require('./resources/instagram.png'),
    tiktok:require('./resources/tiktok.png'),
    younglady:require('./resources/younglady.png'),
    men:require('./resources/men.png'),
    landing1:require('./resources/Mask Group 14.svg'),
    landing2:require('./resources/Group 10844.svg'),
    siki_bg:require('./resources/SIKI BG.png'),
    bg:require('./resources/Group 10844.png'),
    signin_bg:require('./resources/signin_bg.png'),
    next_icon_hover:require('./resources/Next.svg'),
    prev_icon_hover:require('./resources/Previous.svg'),
    next_icon:require('./resources/Next1.svg'),
    prev_icon:require('./resources/Previous1.svg'),
    bg_video:require('./resources/bg-video.mp4'),
    login_bg:require('./resources/login-bg.png'),


    //sidenav
    home_default:require('./resources/sidenav/Home 1.svg'),
    home_hover:require('./resources/sidenav/Home 2.svg'),
    home_enter:require('./resources/sidenav/Home 3.svg'),

    premium_default:require('./resources/sidenav/Premium 1.svg'),
    primium_hover:require('./resources/sidenav/Premium 2.svg'),
    primium_enter:require('./resources/sidenav/Premium 3.svg'),

    music_default:require('./resources/sidenav/Music 1.svg'),
    music_hover:require('./resources/sidenav/Music 2.svg'),
    music_enter:require('./resources/sidenav/Music 3.svg'),

    playlist_default:require('./resources/sidenav/Playlist 1.svg'),
    playlist_hover:require('./resources/sidenav/Playlist 2.svg'),
    playlist_enter:require('./resources/sidenav/Playlist 3.svg'),
    black_playlist:require('./resources/Dropdown.svg'),

    popular_default:require('./resources/sidenav/Popular 2.svg'),
    popular_enter:require('./resources/sidenav/Popular.svg'),

    album_default:require('./resources/sidenav/Album 1.svg'),
    album_hover:require('./resources/sidenav/Album 2.svg'),
    album_enter:require('./resources/sidenav/Album 3.svg'),

    nft_default:require('./resources/sidenav/NFT 1.svg'),
    nft_hover:require('./resources/sidenav/NFT 2.svg'),
    nft_enter:require('./resources/sidenav/NFT 3.svg'),

    subs_default:require('./resources/sidenav/Subscription 1.svg'),
    subs_hover:require('./resources/sidenav/Subscription 2.svg'),
    subs_enter:require('./resources/sidenav/Subscription 3.svg'),

    unlocked_default:require('./resources/sidenav/Unlocked 1.svg'),
    unlocked_hover:require('./resources/sidenav/Unlocked 2.svg'),
    unlocked_enter:require('./resources/sidenav/Unlocked 3.svg'),


    banner1:require('./resources/Group 11486.png'),
    banner2:require('./resources/Group 11488.png'),
    arr:require('./resources/fi-rr-angle-left.png'),
    arr_bg:require('./resources/Ellipse 6.png'),
    //wallet
    add_siki_coin:require('./resources/Add SIKI Coins.svg'),
    add_siki_coin1:require('./resources/Add SIKI Coins 2.svg'),
    transction:require('./resources/Transaction.svg'),
    transction1:require('./resources/Transaction 2.svg'),
    play:require('./resources/Component Play.svg'),
    arrow_down:require('./resources/fi-rr-angle-down.svg'),
    clear:require('./resources/Mask Group 158.svg'),
    tip_player:require('./resources/Group 10386.svg'),
    tip_hover_player:require('./resources/Group 10386 (1).svg'),
    level:require('./resources/Level Graph png.png'),
    upload:require('./resources/music-square-add.svg'),
    listen:require('./resources/Mask Group 44.svg'),
    listenYellow:require('./resources/listenYellow.svg'),
    hbar:require('./resources/hbar_symbol_circle_white.png'),
    hbar_1:require('./resources/hbar_symbol_circle_white.svg'),
    hbar_2:require('./resources/hedera-hashgraph.svg'),

    album:require('./resources/SIKI Album.png'),
    tip_blue:require('./resources/Mask Group 161.svg'),
    listen_yellow:require('./resources/listen.svg'),
    level1:require('./resources/Mask Group 209.svg'),
    link:require('./resources/external-link-line.svg'),
    heart:require('./resources/Path_33961.svg'),
    show:require('./resources/Show.svg'),
    nft_amount:require('./resources/Group 11555.svg'),
    nft_buy:require('./resources/Group 11555 (1).svg'),
    nft_owned:require('./resources/Rectangle 6567.svg'),
    playlist_add:require('./resources/Group 11553.svg'),
    share_link:require('./resources/Mask Group 222.png'),
    user_add:require('./resources/user_add_line.svg'),
    playlist:require('./resources/Group 11552.svg'),
    user_dummy:require('./resources/group-community.svg'),
    sikilogo:require('./resources/Path 13453.svg'),
    //Player
    shuffle:require('./resources/Shuffle Song.svg'),
    shuffle_active:require('./resources/Shuffle Song (1).svg'),
    repeat:require('./resources/All Default.svg'),
    repeat_active:require('./resources/All State 4.svg'),
    discord:require('./resources/Discord.png'),
    twitter:require('./resources/Twitter.png'),
    insta:require('./resources/Insta.png'),
    activity:require('./resources/activity.svg'),
    notification:require('./resources/Notification.svg'),
    notification1:require('./resources/Notification 2.svg'),
    notification_without_dot:require('./resources/notificaiton_without_dot.svg'),
    play_white:require('./resources/Group 10758.svg'),
    listen_first:require('./resources/music-fill.svg'),
    top_playlist:require('./resources/Group 10947.svg'),
    music_play:require('./resources/music-play.svg'),
    arrowdown:require('./resources/Group 6991.svg'),
    flag:require('./resources/flag.svg'),
    siki_coin:require('./resources/SIKI Token.png'),
    apple_icon:require('./resources/apple.svg'),

    //chat
    chat:require('./resources/chat.svg'),
    send:require('./resources/send-message-icon.svg'),
    send_active:require('./resources/send-message-icon 1.svg'),
    emoji:require('./resources/Emoji.svg'),
    done:require('./resources/done.svg'),
    chat_drop:require('./resources/Group 11558.svg'),
    chat_playlist:require('./resources/Group 15456.png'),

    //server down
    server_down:require('./resources/Server Down.png'),
    server_down_mbl:require('./resources/ServerDownMobile.png'),
    server_down_2:require('./resources/ServerDown2.JPG'),
    server_down_2_mbl:require('./resources/ServerDownMobile2.JPG'),
    returnInApril:require('./resources/returnInApril.jpg'),
    returnInApril_mbl:require('./resources/returnInApril_mbl.png'),
    NFT:require('./resources/NFT filled icon.svg'),
    share:require('./resources/share-outline.svg'),
    GIFT:require('./resources/Gift.svg'),
    user_black:require('./resources/user_black.svg'),

    //default
    default_user:require('./resources/default/Circle profile pic.svg'),
    default_user_square:require('./resources/default/Square profile pic.svg'),
    default_cover:require('./resources/default/Cover.svg'),
    default_playlist:require('./resources/default/Playlist.svg'),
    no_internet:require('./resources/no_wifi.svg'),
    default_album:require('./resources/default/Album.svg'),
    default_music:require('./resources/default/Square music.svg'),
    circle_music:require('./resources/default/Circle music.svg'),
    circle_playlist:require('./resources/default/Circle playlist.svg'),
    more_details:require('./resources/nftTabs/More details.svg'),
    more_details_active:require('./resources/nftTabs/More details (Active).svg'),
    offers:require('./resources/nftTabs/Offer .svg'),
    offers_active:require('./resources/nftTabs/Offer (active).svg'),
    Activity:require('./resources/nftTabs/Activity.svg'),
    activity_active:require('./resources/nftTabs/Activity (Active).svg'),
    all_music:require('./resources/nftTabs/All Music.svg'),
    all_music_active:require('./resources/nftTabs/All Music (Active).svg'),
    playlist_info:require('./resources/nftTabs/Playlist info.svg'),
    playlist_info_active:require('./resources/nftTabs/Playlist info (Active).svg'),
    people:require('./resources/Group icon.svg'),
    polygon:require('./resources/Polygon.svg'),
    polygon_color:require('./resources/MaticColor.svg'),
    hbar_balance:require('./resources/Hbar.png'),
    polygon_balance:require('./resources/Polygon.png'),
    polygoon2:require('./resources/Polygon-3.svg'),
    streaming:require('./resources/Live Stream.png'),
    video_Camera:require('./resources/Icon awesome-video.png'),
    streaming_filled:require('./resources/Go Live_Filled.png'),
    video_Camera_filled:require('./resources/Go Live_No Text_Filled.png'),
    speaking:require('./resources/sound bar.gif'),
    pin:require('./resources/pin.png'),
    unmute:require('./resources/unmute.png'),

    // Share
    facebook:require('./resources/Facebook.svg'),
    copy:require('./resources/Copy.svg'),
    twitter_t:require('./resources/Twitter.svg'),
    playlist_tip:require('./resources/Tipped Icon.png'),
    Most_Listened:require('./resources/Most Listened@2x.png'),
    record:require('./resources/Dirt_record.gif'),
    gift_closed:require('./resources/gift_closed.gif'),
    gift_open:require('./resources/gift_opened.gif'),
    

    // world:require('./resources/BlankMap-World.svg'),
    world:require('./resources/world.svg'),
    
    // Analytics
    stream:require('./resources/Ana– 75.png'),
    views_ico:require('./resources/Mask Group 217.png'),
    like_ico:require('./resources/Mask Group 218.png'),
    msg_ico:require('./resources/Mask Group 219.png'),
    tip_ico:require('./resources/Mask Group 220.png'),
    stream_ico:require('./resources/Mask Group 221.png'),
    share_ico:require('./resources/Mask Group 222.png'),
    follow:require('./resources/Mask Group 223.png'),

    black_views:require('./resources/Mask Group 230.png'),
    black_like:require('./resources/Mask Group 231.png'),
    black_msg:require('./resources/Mask Group 232.png'),
    black_tip:require('./resources/Mask Group 237.png'),
    black_stream:require('./resources/Mask Group 233.png'),
    black_share:require('./resources/Mask Group 235.png'),
    black_follow:require('./resources/Mask Group 236.png'),

    white_views:require('./resources/Mask Group 242.png'),
    white_like:require('./resources/Mask Group 243.png'),
    white_msg:require('./resources/Mask Group 244.png'),
    white_tip:require('./resources/Mask Group 245.png'),
    white_stream:require('./resources/Mask Group 246.png'),
    white_share:require('./resources/Mask Group 241.png'),
    white_follow:require('./resources/Mask Group 247.png'),
    allMusic:require('./resources/allMusic.svg'),
    song_animation:require('./resources/Music Animation.gif'),
    loader:require('./resources/SKLP_HD.gif'),


    //artist profile
    topTab:require('./artistProfile/Top.svg'),
    top_default:require('./artistProfile/Topt.svg'),
    twitter_a:require('./artistProfile/Group 11088.svg'),
    nft_default:require('./artistProfile/NFT Icon.svg'),
    nft_y:require('./artistProfile/NFT Icony.svg'),
    user_follow:require('./artistProfile/user-add-line.svg'),
    user_follow_yes:require('./artistProfile/user-follow-line_yes.svg'),
    artist_frame:require('./artistProfile/Progress.png'),
    direct_chat:require('./artistProfile/Direct Message White.svg'),
    verify:require('./artistProfile/Verification.svg'),
    
    //near
    near:require('./resources/Near.svg'),
    near_color:require('./resources/NearColor.svg'),
    near_shadow:require('./resources/Near.png'),
    near_nft:require('./resources/Near Logo.svg'),
    hedera:require('./resources/hedera.svg'),

    moonPay:require('./resources/Mask Group 307.png'),
    payPal: require('./resources/paypal.png')
}