import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// import * as serviceWorker from "./serviceWorker";
import App from "./App";
import store from "./store";
import { ThemeProvider } from "@mui/material/styles";

import "react-notifications-component/dist/theme.css";
import { theme } from "./theme/theme";
import "./index.scss";
import { ToastContainer } from "react-toastify";
// import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "react-toastify/dist/ReactToastify.css";
import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
registerLicense(process.env.REACT_APP_SYNCFUSION_KEY);
// Sentry.init({
//   dsn: "https://7fb249ab313c43b6929a1fe30c8dad40@o4504360832860160.ingest.sentry.io/4504361646227456",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
        <ToastContainer />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
